import requestEco from "@/apis/httpEco";
import { OrderDetailResponse, OrdersListParams, OrdersListResponse } from "@/typings/orders";
import { AxiosPromise } from "axios";

export function getOrdersList(
  params: OrdersListParams
): AxiosPromise<OrdersListResponse> {
  return requestEco({
    url: `/orders/`,
    method: "GET",
    params
  });
}

export function getOrderDetail(id: string): AxiosPromise<OrderDetailResponse> {
  return requestEco({
    url: `/order/${id}/`,
    method: "GET"
  });
}

export function patchOrderCancel(id: string) {
  const data = new FormData();
  data.append("action", "cancel");
  return requestEco({
    url: `/order/${id}/`,
    method: "PATCH",
    data
  });
}
